@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: rgb(240, 247, 254);
    --secondary-color: rgba(13, 17, 27, 0.9); 
    }

body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: var(--secondary-color)
    }

header {
    color: var(--primary-color);
    background-color: var(--secondary-color);
    }

main {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    }

.main-container {
    min-height: 75vh;
}